import BaseModel                  from '@/lib/data/model/BaseModel'
import ForgotPasswordValidator    from '@/api/validations/user/ForgotPasswordValidator'
import ForgotPasswordResource     from '@/api/resources/user/ForgotPasswordResource'
import ForgotPasswordSaveResource from '@/api/resources/user/ForgotPasswordSaveResource'
import API                        from '@/api/Api'

export default class ForgotPasswordModel extends BaseModel {
  constructor (payload = {}, resource = ForgotPasswordResource, validator = ForgotPasswordValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async ForgotPassword () {
    return await API.Resource.User.ForgotPassword(this.clone(false, ForgotPasswordSaveResource))
  }
}
