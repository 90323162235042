import BaseResource from '@/lib/data/resource/BaseResource'

export default class LoginResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Username = payload.Username ?? ''
    this.Password = payload.Password ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
